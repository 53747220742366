body {
  background-color: #ddd;
  color: #f3f4f6;
}

textarea,
input {
  background-color: #f9fafb;
  color: #1f2937;
}

textarea::placeholder,
input::placeholder {
  color: #6b7280;
}

.MuiAppBar-colorPrimary {
  background-color: #333333;
}